// $primary:#bbdefb;
// $secondary: #8aacc8;
// $accent: #db4437;
// $background-light: #f1f2f1;
// $dark-font: #000000;
// $light-font: #ffffff;

$primary:#3bbae2;
$secondary: #1b7ab1;
$accent: #1f1e30;
$background-light: #f1f2f1;
$dark-font: #000000;
$light-font: #ffffff;

$white: #ffffff;
$light-grey: #fdfdfd;
$grey: #f9f9f9;

body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    font-size: 12pt;
    background-color: $white;
}

h1, h2, h3, h4 {
    font-family: 'Lato', sans-serif;
}

.main-button-dark {
    border: solid 2px $dark-font;
    background: linear-gradient(to right, $dark-font 50%, rgba(0, 0, 0, 0) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    color: $dark-font;
    font-size: 14pt;
    width: 150px;
    height: 50px;
    outline: none !important;
    transition: all 0.3s ease;

    &:hover {
        background-position: left bottom;
        color: #fff;
        cursor: pointer;
    }
}

.main-button-light {
    border: solid 2px $light-font;
    background: linear-gradient(to right, $light-font 50%, rgba(0, 0, 0, 0) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    color: $light-font;
    font-size: 14pt;
    width: 150px;
    height: 50px;
    outline: none !important;
    transition: all 0.3s ease;

    &:hover {
        background-position: left bottom;
        color: $dark-font;
        cursor: pointer;
    }
}